// console.log('Got to delete_confirmed javascript');

import { createAlert } from './createAlert';

window["hide_deal_image"] = function hide_deal_image(id) {
  $(`#deal-image-${id}`).hide();

  createAlert('info', 'Deal image removed');
}

$(function () {

  $(document).on('confirm:complete', (e) => {
    if (e.originalEvent.detail[0]) {
      // user confirmed!

      if (e.target.dataset.callback) {

        var fn = window[e.target.dataset.callback];
        if (typeof fn === 'function') {
          fn(e.target.dataset.id);
        }

      }
    } else {
      // user canceled!
    }
  });

});
